.app-calendar {
	margin: 0 auto;
	padding: 15px;
	min-width: 320px;
	max-width: 450px;
	color: $c-black;
	border-radius: 10px;
	background-color: $c-white;
	font-weight: $text-weight-normal;

	.calendar {
		display: flex;
		justify-content: center;
		flex-direction: column;

		.header {
			margin: 0;
			padding: 10px 10px 15px;
			color: $c-primary;
			font-size: calc(1em + 1vmin);
			text-transform: uppercase;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&__wrapper {
				display: flex;
				align-content: center;
			}

			&__month {
				margin-right: 15px;
				font-weight: $text-weight-semibold;
			}

			&__year {
				font-weight: $text-weight-semibold;
			}

			&__prev,
			&__next {
				width: 30px;
				height: 30px;
				border: 0;
				border-radius: 30px;
				background-color: transparent;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					background-color: $c-background;
				}

				&:disabled {
					pointer-events: none;

					svg {
						pointer-events: none;

						path {
							stroke: $c-disabled;
						}
					}
				}

				svg {
					pointer-events: none;

					path {
						stroke: $c-primary;
					}
				}
			}

			&__next {
				svg {
					transform: rotate(270deg);
				}
			}

			&__prev {
				svg {
					transform: rotate(90deg);
				}
			}
		}

		.dates {
			padding: 0;
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			&__item {
				aspect-ratio: 1;
				width: calc((100% / 7));
				font-size: calc(0.5em + 1vmin);
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;

				&--previous,
				&--next {
					.dates__day {
						color: #eaeaea;
					}

					&:hover {
						.dates__day {
							color: darken(#f3f3f3, 20%) !important;
							background-color: #f3f3f3 !important;
						}
					}
				}

				&:hover {
					.dates__day {
						background-color: #f3f3f3 !important;
					}
				}

				&--today {
					.dates__day {
						position: relative;

						&:before {
							position: absolute;
							bottom: -3px;
							left: 0;
							right: 0;
							margin: auto;
							content: "";
							display: block;
							width: 8px;
							height: 8px;
							border-radius: 100px;
							background-color: $c-yellow;
						}
					}
				}

				&--active {
					.dates__day {
						color: $c-white;
						background-color: $c-primary;
					}

					&:hover {
						.dates__day {
							background-color: darken($c-primary, 5%) !important;
						}
					}
				}

				&--disabled {
					background-color: lighten($c-disabled, 11%);
					pointer-events: none;

					.dates__day {
						color: darken($c-disabled, 10%);
					}
				}
			}

			&__day {
				width: 80%;
				height: 80%;
				padding: 10px;
				border-radius: 1000px;
				transition: 250ms ease-in 100ms;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

.default-active {
	&:after {
		position: absolute;
		bottom: -5px;
		left: 0;
		right: 0;
		margin: 0 auto;
		content: "";
		display: block;
		width: 50%;
		height: 15px;
		border-radius: 100px;
	}
}
