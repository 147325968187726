.page-dashboard {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px;
	}

	.dashboard {
		padding-bottom: 25px;

		&__header {
			padding: 15px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__headline {
			margin: 0;
			color: $c-black;
			font-weight: $text-weight-normal;
		}

		&__views {
			display: flex;
			align-items: center;
		}

		&__icon {
			width: 15px;
			height: 15px;
			transform: rotate(-90deg);
		}

		&__text {
			margin: 0 5px;
			color: $c-primary;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
		}

		&__container {
			width: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
		}

		&__section {
			margin: 15px 0;
			gap: 15px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
		}

		.card-table {
			padding: 25px;
			border-radius: 10px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);

			.app-table {
				padding: 0;

				.table {
					padding: 0;

					&__tbody {
						box-shadow: none;
					}

					&__th,
					&__td {
						padding: 15px 20px;
					}
				}
			}
		}

		.card-chart {
			position: relative;
			width: 100%;
			padding: 25px;
			border-radius: 10px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);

			&__title {
				margin: 0 0 15px;
				color: $c-primary;
				font-weight: $text-weight-normal;
			}

			&__header {
				padding: 0 0 20px;

				@include min-width($scr-md) {
					display: flex;
					align-items: center;
				}
			}

			&__list {
				padding: 0;
				margin: 0 0 0 auto;
				display: flex;
				align-items: center;

				@include max-width($scr-md) {
					margin: 25px 0 0;
				}
			}

			&__item {
				list-style-type: none;
				padding: 5px 15px;
				margin-left: 5px;
				border-radius: 100px;
				color: $c-dark-grey;
				font-size: $text-size-s;
				transition: $transition;
				cursor: pointer;

				&:hover {
					color: $c-blue;
					background-color: rgba(2, 69, 169, 0.1);
				}

				&--active {
					color: $c-blue;
					background-color: rgba(2, 69, 169, 0.1);
				}
			}
		}

		.card {
			flex: 1;
			min-height: 180px;
			min-width: 300px;
			padding: 25px;
			border-radius: 10px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@include max-width($scr-md) {
				width: 100%;
				max-width: 100%;
			}

			&__header {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__body {
			}

			&__footer {
				gap: 10px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}

			&__results {
				display: flex;
				align-items: center;
			}

			&__title {
				margin: 0;
				color: $c-black;
			}

			&__points {
				margin: 15px 0;
				color: $c-primary;
				font-size: $text-size-xxl;
				font-weight: $text-weight-normal;
			}

			&__no {
				margin: 0;
				padding: 5px 10px;
				color: $c-white;
				font-size: $text-size-s;
				background-color: $c-light-grey;
				border-radius: 100px;

				&--warning {
					color: $c-red;
					background-color: rgba(246, 68, 68, 0.1);
				}

				&--positive {
					color: $c-green;
					background-color: rgba(0, 207, 156, 0.1);
				}
			}

			&__label {
				margin: 0 5px;
				color: $c-light-grey;
				font-size: $text-size-s;
			}

			&__values {
				display: flex;
				align-items: center;
			}

			&__downturn {
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-bottom: 5px solid $c-light-grey;

				&--increase {
					border-bottom: 5px solid $c-green;
				}
			}

			&__price {
				font-weight: $text-weight-semibold;

				&--warning {
					color: $c-red;
				}

				&--positive {
					color: $c-green;
				}
			}

			&__text {
				margin: 0 5px;
				color: $c-dark-grey;
				font-size: $text-size-s;
			}
		}
	}
}
