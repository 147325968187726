.page-user-roles-privileges {
	height: 100%;

	.roles-privileges {
		height: 100%;

		.app-table {
			.table {
				&__members {
					color: $c-dark-grey;
					text-align: center;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
				}
			}
		}
	}
}
