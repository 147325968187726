.app-empty-data {
	height: 100%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	.empty-data {
		&__image {
			width: 250px;
		}

		&__title {
			color: $c-dark-grey;
			font-size: $text-size-m;
		}

		&__description {
			margin: 0 0 25px;
			color: $c-dark-grey;
		}
	}
}
