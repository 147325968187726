.page-customers {
	height: 100%;

	.customers {
		height: 100%;

		&__results {
			padding: 10px 0;
			margin: 10px 0;
			border-bottom: 1px dashed $c-disabled;
		}

		&__text {
			margin: 0;
			font-weight: $text-weight-semibold;
		}

		.app-table {
			.table {
				&__total-points {
					padding: 0 15px 0 0;
					text-align: right;
					color: $c-dark-grey;
					white-space: nowrap;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
				}
			}
		}
	}
}