.page-affiliate-profile {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px;
	}

	.affiliate-profile {
		padding-bottom: 25px;

		&__form {
			width: 100%;
			max-width: 1280px;
		}

		&__container {
			margin: 15px 0;
			gap: 15px;
			display: flex;
		}

		&__name {
			margin: 0;
			color: $c-blue;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__title {
			margin: 0;
			color: $c-black;
			font-weight: $text-weight-semibold;
		}

		&__date {
			margin: 5px 0;
			color: $c-light-grey;
			font-size: $text-size-xs;
		}

		&__box-header {
			margin: 0 0 25px;
			padding: 0 0 15px;
			border-bottom: 1px dashed #d3e0e8;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__box {
			flex: 1;
			padding: 15px;
			border-radius: 8px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			display: flex;
			flex-direction: row;
		}

		&__wrapper {
			flex: 1;
		}

		&__box-body {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			gap: 20px 35px;

			.app-input,
			.app-mobile-input,
			.app-select-status {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}

			.app-input {
				&--multiline {
					width: 100%;
				}
			}
		}

		&__list {
			width: 100%;
			padding: 0;
			margin: 0;
			gap: 15px;
			display: flex;
			flex-direction: column;
		}

		&__item {
			width: 100%;
			list-style-type: none;
		}

		&__product {
			margin: 0;
			color: $c-black;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__url {
			padding: 15px;
			margin: 15px 0 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			word-break: break-all;
			background-color: #f8f8f8;
			display: flex;
			align-items: flex-start;
		}

		&__copy {
			margin: 0 0 0 5px;
			cursor: pointer;
		}

		&__error {
			color: $c-red;
			font-size: $text-size-xs;
		}

		&__button-container {
			width: 100%;
			max-width: 350px;
			margin-left: auto;
			gap: 15px;
			display: flex;

			.app-button {
				flex: 1;

				&--outline {
					width: auto;
					border: 0;
					background-color: transparent;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
