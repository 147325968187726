.app-select-status {
	width: 100%;

	&__label {
		margin: 0 0 5px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__required {
		color: $c-red;
	}

	&__container {
		width: 65%;
		gap: 15px;
		display: flex;
	}

	&__button {
		flex: 1;
		padding: 8px 10px;
		border-radius: 8px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		background-color: transparent;
		border: 1px solid $c-light-grey;
		cursor: pointer;

		&--active {
			color: $c-primary;
			border: 1px solid $c-primary;
			font-weight: $text-weight-semibold;
			background-color: rgba(0, 207, 156, 0.1);
		}
	}

	&__error {
		margin: 8px 0 0;
		color: $c-red;
		font-size: $text-size-xs;
		font-weight: $text-weight-normal;
	}
}
