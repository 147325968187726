.app-customer-modal {
	min-width: 300px;

	.customer-modal {
		width: 100%;
		height: 100%;
		max-width: 560px;
		margin-left: auto;
		padding: 45px 0 0;
		background-color: $c-white;

		&__main {
			width: 100%;
			height: 100%;

			@include max-width($scr-xs) {
				overflow-y: scroll;
				scrollbar-width: none;
				-ms-overflow-style: none;

				&::-webkit-scrollbar {
					width: 0;
					height: 0;
				}
			}
		}

		&__header {
			padding: 0 45px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include max-width($scr-xs) {
				padding: 0 35px;
				text-align: center;
				flex-direction: column;
			}

			.app-button {
				width: auto;
				padding: 8px 15px;
				border-radius: 8px;
				background-color: transparent;
				border: 1px solid $c-primary;
				flex-direction: row-reverse;

				&__label {
					color: $c-primary;
					margin: 0 5px 0 0;
					font-size: $text-size-s;
				}

				.app-icon {
					width: 18px;
					height: 18px;
				}
			}
		}

		&__profile {
			margin: 0 25px 0 10px;

			@include max-width($scr-xs) {
				margin: 15px;
			}
		}

		&__image {
			width: 70px;
			height: 70px;
			border-radius: 70px;
			border: 4px solid $c-white;
			overflow: hidden;
			box-shadow: 2px 4px 8px 0px rgba(37, 37, 37, 0.08);
			flex-shrink: 0;

			img {
				width: 100%;
			}
		}

		&__close-button {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 30px;
			height: 30px;
			border: 0;
			cursor: pointer;
			background-color: transparent;
		}

		.info {
			padding: 0 45px;
			margin: 25px 0;
			display: flex;
			align-items: stretch;
			gap: 10px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}

			&__box {
				flex: 1;
				padding: 20px 12.5px;
				background-color: #f8f8f8;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				.customer-modal__text {
					margin: 0 0 10px;
					text-align: center;
					white-space: break-all;
				}

				.customer-modal__title {
					text-align: center;
					word-break: break-all;
				}
			}
		}

		&__tabs {
			margin: 20px 45px 0;
			border-bottom: 1px solid $c-disabled;
			display: flex;

			@include max-width($scr-xs) {
				margin: 20px 35px 0;
			}
		}

		&__tab {
			position: relative;
			padding: 12.5px 25px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			border: 0;
			cursor: pointer;
			transition: $transition;
			background-color: transparent;
			flex-grow: 1;

			&--active {
				color: $c-blue;
				font-weight: $text-weight-semibold;

				&:after {
					content: "";
					position: absolute;
					bottom: -1.5px;
					left: 0;
					right: 0;
					display: block;
					width: 100%;
					height: 2px;
					background-color: $c-green;
				}
			}
		}

		&__body {
			height: calc(100% - 200px);
			padding: 25px 45px;
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&--activity {
				padding: 0 45px;
			}

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			@include max-width($scr-xs) {
				height: auto;
				overflow: hidden;
				padding: 25px 35px;

				&--activity {
					padding: 0 45px;
				}
			}
		}

		&__container {
			margin: 0 0 25px;
		}

		&__title {
			margin: 0;
			font-weight: $text-weight-semibold;
		}

		&__wrapper {
			width: 100%;
			padding: 0 0 15px;
			border-bottom: 1px dashed $c-disabled;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
		}

		&__info {
			width: 50%;
			margin: 10px 0;
			padding: 0 15px 0 0;

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__label {
			margin: 5px 0;
			color: $c-light-grey;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__value {
			margin: 0;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__text {
			margin: 5px 0 0;
			color: $c-light-grey;
			word-wrap: break-word;
			font-size: $text-size-xs;
		}

		&__note {
			color: $c-black;
			word-wrap: break-word;
			font-size: $text-size-xs;
		}

		&__activity-body {
			height: calc(100% - 200px);
			padding: 0 45px;
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				display: none;
			}

			@include max-width($scr-xs) {
				height: auto;
				overflow: hidden;
				padding: 0 35px;
			}
		}

		&__activities {
			padding: 20px 0 0;
		}

		&__activity {
			position: relative;
			min-height: 50px;
			padding: 10px 0 0;
			display: flex;

			&:before {
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				margin: 3px 5px 0 0;
				border-radius: 8px;
				background-color: $c-blue;
			}

			&:after {
				position: absolute;
				left: 2.5px;
				top: 25px;
				content: "";
				display: block;
				height: 20px;
				width: 1px;
				border-left: 1px dashed $c-light-grey;
			}

			.customer-modal__text {
				margin: 0 10px;
			}
		}

		&__item {
			.customer-modal__text {
				margin: 2.5px 0 0;
			}
		}
	}
}
