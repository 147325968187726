.page-maintenance {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.maintenance {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		&__hero {
			width: 300px;

			img {
				width: 100%;
			}
		}

		&__title {
			margin: 0;
			padding: 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-xl;
			font-weight: $text-weight-bold;
		}

		&__description {
			margin: 10px 0;
			padding: 0 20px;
			text-align: center;
			color: $c-dark-grey;
		}
	}
}
