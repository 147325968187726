.app-header {
	width: calc(100% - 70px);
	margin: 0 35px 20px;
	padding: 35px 0 20px;
	border-bottom: 1px solid $c-light-grey;

	@include max-width($scr-md) {
		width: calc(100% - 30px);
		margin: 0 15px 15px;
	}

	@include max-width($scr-sm) {
		width: 100%;
		margin: 0 0 15px;
		padding: 0 20px 0 0;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__menu {
			width: 50px;
			height: 50px;
			border: 0;
			background-color: rgb(3, 149, 150);
			background-position: center;
			background-repeat: no-repeat;
			background-image: url("../../assets/images/components/app-header/menu-icon.svg");
			cursor: pointer;
			flex-shrink: 0;
			display: none;

			@include max-width($scr-sm) {
				display: block;
			}

			&--shrinked {
				background-image: url("../../assets/images/components/app-header/close-icon.svg");
			}
		}

		&__title {
			margin: 0;
			color: $c-blue;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			@include max-width($scr-sm) {
				margin-left: 20px;
				margin-right: auto;
				font-size: $text-size-body;
			}
		}

		&__back {
			border: 0;
			padding: 0;
			cursor: pointer;
			background-color: transparent;
			display: flex;
			align-items: center;

			@include max-width($scr-sm) {
				margin-left: 15px;
				margin-right: auto;
			}
		}

		&__back-icon {
			width: 20px;
			height: 20px;
			margin: 0 10px 0 0;
		}

		&__content {
			@include max-width($scr-sm) {
				display: none;
			}
		}

		&__profile {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		&__name {
			margin: 0;
			font-weight: $text-weight-semibold;
		}

		&__text {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__image {
			margin: 0 20px 0 10px;
			width: 50px;
			height: 50px;
			border-radius: 100px;
			border: 5px solid $c-white;
			box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

			img {
				width: 100%;
				border-radius: 100px;
			}

			@include max-width($scr-sm) {
				width: 35px;
				height: 35px;
			}
		}

		&__notification {
			position: relative;
			width: 23px;
			height: 23px;

			@include max-width($scr-sm) {
				width: 18px;
				height: 18px;
			}

			img {
				width: 100%;
			}

			&:before {
				content: "";
				position: absolute;
				top: 5px;
				right: 0px;
				width: 8px;
				height: 8px;
				border-radius: 10px;
				background-color: $c-red;
			}
		}
	}
}
