.page-404 {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	&__title {
		margin: 0;
		color: $c-primary;
		font-size: $text-size-xxxl * 5;
		font-weight: $text-weight-bold;
		animation: fade 1s infinite;
	}
}

@keyframes fade {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	50% {
		opacity: 0.5;
		transform: scale(0.95);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
