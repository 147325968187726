.page-claims {
	height: 100%;

	.claims {
		height: 100%;

		.app-search-input {
			//
			&__button-container {
				//
				.app-button {
					border: 1px solid $c-primary;
					background-color: transparent;

					&__label {
						color: $c-primary;
					}
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		&__results {
			padding: 10px 0;
			margin: 10px 35px;
			border-bottom: 1px dashed $c-disabled;

			@include max-width($scr-sm) {
				margin: 10px 15px;
			}
		}

		&__text {
			margin: 0;
			font-weight: $text-weight-semibold;
		}

		.app-tabs {
			margin: 0 35px;

			@include max-width($scr-sm) {
				margin: 0 15px;
			}
		}
	}
}
