.app-years {
	//
	.years {
		padding: 0;
		margin: 0;
		max-height: 350px;
		overflow-y: scroll;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none; /* Firefox */

		&::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}

		&__item {
			padding: 15px 0;
			width: calc(100% / 4);
			text-align: center;
			list-style-type: none;
			cursor: pointer;

			&--active {
				.years__year {
					@extend .years-hover;

					span {
						color: $c-primary;
						font-weight: $text-weight-semibold;
					}
				}
			}

			&--current {
				.years__year {
					span {
						&:before {
							position: absolute;
							right: -35%;
							top: -1px;
							bottom: 0;
							margin: auto;
							content: "";
							display: block;
							width: 8px;
							height: 8px;
							border-radius: 100px;
							background-color: $c-red;
						}
					}
				}
			}
		}

		&__year {
			position: relative;
			width: 100%;
			font-size: calc(1em + 1vmin);
			@extend .default-active;

			&:hover {
				@extend .years-hover;
			}

			span {
				position: relative;
				z-index: 99999;
			}
		}
	}
}

.years-hover {
	&:after {
		position: absolute;
		bottom: 0;
		left: 20%;
		margin: 0;
		content: "";
		display: block;
		width: 25px;
		height: 25px;
		border-radius: 100px;
		background-color: $c-yellow;
		transition: 250ms ease-in 100ms;
	}
}
