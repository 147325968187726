.page-campaigns {
	height: 100%;

	.campaigns {
		height: 100%;

		&__results {
			padding: 10px 0;
			margin: 10px 35px;
			border-bottom: 1px dashed $c-disabled;

			@include max-width($scr-sm) {
				margin: 10px 15px;
			}
		}

		&__text {
			margin: 0;
			font-weight: $text-weight-semibold;
		}
	}
}
