@mixin max-width($width) {
	@media only screen and (max-width: $width) {
		@content;
	}
}

@mixin min-width($width) {
	@media only screen and (min-width: $width) {
		@content;
	}
}

@mixin font($font-family, $font-file) {
	@font-face {
		font-family: $font-family;
		src: url($font-file + ".eot");
		src: url($font-file + ".eot?#iefix") format("embedded-opentype"), url($font-file + ".woff") format("woff"), url($font-file + ".woff2") format("woff2"), url($font-file + ".ttf") format("truetype"), url($font-file + ".svg#" + $font-family) format("svg");
		font-weight: normal;
		font-style: normal;
	}
}
