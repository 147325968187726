.app-weekdays {
	//
	.weekdays {
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;

		&__item {
			padding: 10px;
			width: calc((100% / 7));
			font-size: calc(0.5em + 1vmin);
			text-align: center;
			list-style-type: none;
		}

		&__weekday {
			text-transform: uppercase;
			color: darken(#eaeaea, 15%);
			pointer-events: none;
			transition: 150ms ease;
		}
	}
}
