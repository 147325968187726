.app-claim-modal {
	min-width: 300px;

	.claim-modal {
		width: 100%;
		height: 100%;
		max-width: 560px;
		margin-left: auto;
		padding: 45px 0 0;
		background-color: $c-white;

		&__main {
			width: 100%;
			height: 100%;

			@include max-width($scr-xs) {
				overflow-y: scroll;
				scrollbar-width: none;
				-ms-overflow-style: none;

				&::-webkit-scrollbar {
					width: 0;
					height: 0;
				}
			}
		}

		&__header {
			padding: 0 45px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}
		}

		&__profile {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.claim-modal__text {
				color: $c-dark-grey;
			}
		}

		&__close-button {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 30px;
			height: 30px;
			border: 0;
			cursor: pointer;
			background-color: transparent;
		}

		.info {
			padding: 0 45px;
			margin: 25px 0;
			display: flex;
			align-items: stretch;
			gap: 10px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}

			&__box {
				flex: 1;
				padding: 20px 12.5px;
				background-color: #f8f8f8;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				.claim-modal__text {
					margin: 0 0 10px;
					text-align: center;
					white-space: break-all;
				}

				.claim-modal__title {
					text-align: center;
					word-break: break-all;
				}
			}
		}

		&__tabs {
			margin: 20px 45px 0;
			padding-bottom: 5px;
			border-bottom: 1px solid $c-disabled;
			display: flex;
			overflow-y: hidden;
			overflow-x: scroll;

			@include max-width($scr-xs) {
				margin: 20px 35px 0;
			}
		}

		&__body {
			height: calc(100% - 300px);
			padding: 25px 45px;
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&--activity {
				padding: 0 45px;
			}

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			@include max-width($scr-xs) {
				height: auto;
				overflow: hidden;
				padding: 25px 35px;

				&--activity {
					padding: 0 45px;
				}
			}
		}

		&__container {
		}

		&__content {
			width: 100%;

			.claim-modal__row {
				margin: 15px 0;

				.claim-modal__label,
				.claim-modal__value {
					margin: 0;
				}
			}
		}

		&__title {
			margin: 15px 0;
			font-weight: $text-weight-semibold;
		}

		&__wrapper {
			width: 100%;
			border-bottom: 1px dashed $c-disabled;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
		}

		&__info {
			width: calc(50% - 7.5px);
			margin: 0 0 15px;

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__label {
			margin: 0 0 5px;
			color: $c-light-grey;
			word-break: break-word;
			font-size: $text-size-s;
		}

		&__value {
			margin: 0;
			word-break: break-word;
			font-size: $text-size-s;
		}

		&__text {
			margin: 5px 0 0;
			color: $c-light-grey;
			word-break: break-word;
			font-size: $text-size-xs;
		}

		&__link {
			color: #13a7f4;
			font-size: $text-size-s;
		}

		&__documents {
			padding: 0;
		}

		&__item {
			padding: 15px 35px;
			list-style-type: none;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:nth-child(odd) {
				background-color: #f8f8f8;
			}

			.claim-modal__label {
				padding: 0 10px 0 0;

				&:last-child {
					width: 15%;
				}
			}
		}

		&__docs-header {
			padding: 0 35px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.claim-modal__label {
				width: 33%;
				color: $c-dark-grey;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;

				&:last-child {
					width: 15%;
				}
			}
		}
	}
}
