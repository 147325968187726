.app-months {
	.months {
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		&__item {
			position: relative;
			aspect-ratio: 1.5;
			width: calc(100% / 3);
			text-align: center;
			list-style-type: none;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				.months__number {
					opacity: 0.5;
					transform: scale(1.1);
				}

				.months__text {
					transform: scale(1.1);
					color: darken($c-primary, 15%);
				}
			}

			&--active {
				.months__month {
					position: relative;

					&:before {
						position: absolute;
						width: 35px;
						height: 35px;
						left: 20%;
						bottom: 20%;
						border-radius: 200px;
						content: "";
						display: block;
						background-color: $c-yellow;
						font-weight: $text-weight-semibold;
						z-index: 0;
					}
				}
			}

			&--current {
				.months__text {
					&:before {
						position: absolute;
						top: -10px;
						left: 0;
						right: 0;
						margin: auto;
						content: "";
						display: block;
						width: 8px;
						height: 8px;
						border-radius: 100px;
						background-color: $c-red;
						z-index: 100;
					}
				}
			}
		}

		&__number {
			position: absolute;
			left: 18%;
			opacity: 0.2;
			color: $c-primary;
			font-weight: $text-weight-strong;
			font-size: calc(50px + 0.5vmin);
			transition: $transition;
			z-index: 100;
		}

		&__text {
			position: relative;
			margin-bottom: calc(0.9em + 1vmin);
			color: $c-black;
			font-size: calc(0.4em + 1vmin);
			font-weight: $text-weight-semibold;
			transition: $transition;
			z-index: 100;
			align-self: flex-end;
		}

		&__month {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
