.page-reporting {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px;
	}

	.reporting {
		padding-bottom: 25px;

		&__title {
			margin: 0;
			color: $c-blue;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__form {
			width: 100%;
			max-width: 1280px;
		}

		&__container {
			margin: 15px 0;
			gap: 15px;
			display: flex;
			flex-direction: column;
		}

		&__box {
			flex: 1;
			padding: 15px;
			border-radius: 8px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			display: flex;
			flex-direction: row;
		}

		&__wrapper {
			flex: 1;
		}

		&__box-body {
			margin: 0 0 20px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			gap: 20px 35px;

			.app-input,
			.app-select-input,
			.app-calendar-input {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}
		}

		&__progress-bar {
			width: 0;
			height: 2px;
			background-color: $c-green;
			transition: $transition;
		}

		&__button-container {
			width: 100%;
			max-width: 350px;
			margin-left: auto;
			gap: 15px;
			display: flex;

			.app-button {
				flex: 1;

				&--outline {
					width: auto;
					border: 0;
					background-color: transparent;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
