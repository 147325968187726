.app-scrollable-tabs {
	padding: 0 25px;
	display: flex;
	align-items: center;

	&__scroll-left {
		width: 30px;
		height: 30px;
		border: 0;
		cursor: pointer;
		border-radius: 8px;
		transform: rotate(90deg);
		background-color: transparent;
		transition: $transition;
		opacity: 0;
		flex-shrink: 0;

		&:disabled {
			opacity: 0;
		}

		&--active {
			opacity: 1;
		}

		svg {
			path {
				stroke: $c-light-grey;
				transition: $transition;
			}
		}

		&:hover {
			background-color: rgba($c-light-grey, 0.2);

			svg {
				path {
					stroke: $c-dark-grey;
				}
			}
		}
	}

	&__scroll-right {
		width: 30px;
		height: 30px;
		border: 0;
		cursor: pointer;
		border-radius: 8px;
		transform: rotate(-90deg);
		background-color: transparent;
		transition: $transition;
		opacity: 0;
		flex-shrink: 0;

		&:disabled {
			opacity: 0;
		}

		&--active {
			opacity: 1;
		}

		svg {
			path {
				stroke: $c-light-grey;
				transition: $transition;
			}
		}

		&:hover {
			background-color: rgba($c-light-grey, 0.2);

			svg {
				path {
					stroke: $c-dark-grey;
				}
			}
		}
	}

	&__list {
		width: 100%;
		margin: 0;
		padding: 0 0 5px;
		display: flex;
		flex-direction: row;
		overflow-y: hidden;
		overflow-x: scroll;
		scroll-behavior: smooth;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}

	&__item {
		position: relative;
		margin: 0;
		padding: 10px 25px;
		list-style-type: none;
		cursor: pointer;
		transition: $transition;

		&--active {
			.app-scrollable-tabs__label {
				color: $c-primary;
				font-weight: $text-weight-semibold;
			}

			&:after {
				content: "";
				position: absolute;
				bottom: -1.5px;
				left: 0;
				right: 0;
				display: block;
				width: 100%;
				height: 2px;
				background-color: $c-green;
			}
		}
	}

	&__label {
		margin: 0;
		color: $c-dark-grey;
		font-size: $text-size-s;
		white-space: nowrap;
	}
}
