.app-layout {
	width: 100%;
	height: 100%;
	min-width: 320px;
	background-color: rgb(3, 149, 150);
	background: linear-gradient(157deg, rgba(3, 149, 150, 1) 0%, rgba(9, 166, 148, 1) 40%, rgba(22, 202, 144, 1) 100%);

	.main {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;

		&__container {
			position: relative;
			width: calc(100% - $side-nav-width);
			max-height: 100vh;
			min-height: calc(100vh - 55px);
			border-top-left-radius: 25px;
			border-bottom-left-radius: 25px;
			background-color: $c-background;
			transition: $transition;
			display: flex;
			flex-direction: column;

			&--shrinked {
				width: calc(100% - $side-nav-shrinked-width);
			}

			@include max-width($scr-sm) {
				width: 100%;
				z-index: 1298;
				border-radius: 0;
			}
		}

		&__page {
			margin: 5px 0 0 0;
			flex: 1;
			overflow: scroll;
			overflow-x: hidden;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__copyright {
			padding: 10px;
			margin: auto 0 0;
			text-align: center;
			color: $c-light-grey;
			font-size: $text-size-xs;
		}
	}
}
