@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/poppins/Poppins-SemiBold.eot");
	src: url("../assets/fonts/poppins/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/poppins/Poppins-SemiBold.woff2") format("woff2"), url("../assets/fonts/poppins/Poppins-SemiBold.woff") format("woff"),
		url("../assets/fonts/poppins/Poppins-SemiBold.ttf") format("truetype"), url("../assets/fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/poppins/Poppins-Regular.eot");
	src: url("../assets/fonts/poppins/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/poppins/Poppins-Regular.woff2") format("woff2"), url("../assets/fonts/poppins/Poppins-Regular.woff") format("woff"),
		url("../assets/fonts/poppins/Poppins-Regular.ttf") format("truetype"), url("../assets/fonts/poppins/Poppins-Regular.svg#Poppins-Regular") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/poppins/Poppins-Medium.eot");
	src: url("../assets/fonts/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/poppins/Poppins-Medium.woff2") format("woff2"), url("../assets/fonts/poppins/Poppins-Medium.woff") format("woff"),
		url("../assets/fonts/poppins/Poppins-Medium.ttf") format("truetype"), url("../assets/fonts/poppins/Poppins-Medium.svg#Poppins-Medium") format("svg");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/poppins/Poppins-Bold.eot");
	src: url("../assets/fonts/poppins/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/poppins/Poppins-Bold.woff2") format("woff2"), url("../assets/fonts/poppins/Poppins-Bold.woff") format("woff"),
		url("../assets/fonts/poppins/Poppins-Bold.ttf") format("truetype"), url("../assets/fonts/poppins/Poppins-Bold.svg#Poppins-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/poppins/Poppins-Light.eot");
	src: url("../assets/fonts/poppins/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/poppins/Poppins-Light.woff2") format("woff2"), url("../assets/fonts/poppins/Poppins-Light.woff") format("woff"),
		url("../assets/fonts/poppins/Poppins-Light.ttf") format("truetype"), url("../assets/fonts/poppins/Poppins-Light.svg#Poppins-Light") format("svg");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/poppins/Poppins-Thin.eot");
	src: url("../assets/fonts/poppins/Poppins-Thin.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/poppins/Poppins-Thin.woff2") format("woff2"), url("../assets/fonts/poppins/Poppins-Thin.woff") format("woff"),
		url("../assets/fonts/poppins/Poppins-Thin.ttf") format("truetype"), url("../assets/fonts/poppins/Poppins-Thin.svg#Poppins-Thin") format("svg");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/poppins/Poppins-Black.eot");
	src: url("../assets/fonts/poppins/Poppins-Black.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/poppins/Poppins-Black.woff2") format("woff2"), url("../assets/fonts/poppins/Poppins-Black.woff") format("woff"),
		url("../assets/fonts/poppins/Poppins-Black.ttf") format("truetype"), url("../assets/fonts/poppins/Poppins-Black.svg#Poppins-Black") format("svg");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
