.page-endorsement {
	height: 100%;

	.endorsement {
		height: 100%;

		.app-search-input {
			//
			&__button-container {
				//
				.app-button {
					border: 1px solid $c-primary;
					background-color: transparent;

					&__label {
						color: $c-primary;
					}
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		.app-tabs {
			margin: 0 35px;

			@include max-width($scr-sm) {
				margin: 0 15px;
			}
		}

		.app-table {
			.table {
				&__amount {
					color: $c-dark-grey;
					text-align: right;
					white-space: nowrap;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
				}
			}
		}
	}
}
